/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet"


import Header from "./header";
// import Background from "./background";


import styles from "./layout.module.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          subtitle
        }
      }
    }
  `)

  return (
    <div className={styles.wrapper}>
      <Helmet>
          <title>ABC Browser Circus</title>
      </Helmet>
      {/* <Background/> */}

      <Header siteTitle={data.site.siteMetadata.title} subTitle={data.site.siteMetadata.subtitle} />

      {children}
    </div>
  )
}


export default Layout
