import { Link } from "gatsby"
import React from "react"
import styles from "./nav.module.css"

const Nav = () => (
  <div className={styles.scope}>
    <ul> 
      <li><Link to="/">Info</Link></li>
      <li><Link to="/hyperlink-gallery">Hyperlink Gallery</Link></li>
      <li><Link to="/#recent">Recent</Link></li>
      
    </ul>
  </div>
)


export default Nav
