import { Link } from "gatsby"
import React from "react"
import styles from "./header.module.css"

import Nav from "./nav";


const Header = ({ siteTitle, subTitle }) => (
  <header className={styles.scope}>
    <div className={styles.title}>
      <h1 style={{ margin: 0 }}>
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
      <h2 className={styles.subtitle}>
        {subTitle}
      </h2>
    </div>
    <Nav/>
  </header>
)


export default Header
